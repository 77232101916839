import React from 'react';

interface ToggleSwitchProps {
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    labelOn?: string;
    labelOff?: string;
    colorOn?: string;
    colorOff?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    isChecked,
    onChange,
    labelOn = "On",
    labelOff = "Off",
    colorOn = "#e0e09f",
    colorOff = "#666"
}) => {
    return (
        <label style={{ 
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            cursor: 'pointer',
            gap: '1dvw',
            margin: '0.1dvw',
            padding: '0.3dvw',
            outline: '0.04dvw solid hsl(45, 34.00%, 62.50%, 0.5)',
            borderRadius: '0.4dvw',
            backgroundColor: '#191919',
            WebkitTapHighlightColor: 'transparent'
        }}>
            <div style={{
                position: 'relative',
                width: '3.3dvw',
                height: '1.8dvw',
                outline: 'none',
                WebkitTapHighlightColor: 'transparent'
            }}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => onChange(e.target.checked)}
                    style={{ 
                        opacity: 0,
                        width: 0,
                        height: 0,
                        position: 'absolute',
                        outline: 'none'
                    }}
                />
                <span style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: isChecked ? colorOn : colorOff,
                    transition: 'all 0.4s ease',
                    borderRadius: '2dvw',
                    boxShadow: 'inset 0 0 0.3dvw rgba(0,0,0,0.5)',
                    outline: 'none',
                    WebkitTapHighlightColor: 'transparent'
                }}>
                    <div style={{
                        position: 'absolute',
                        height: '1.6dvw',
                        width: '1.6dvw',
                        left: isChecked ? '1.6dvw' : '0.1dvw',
                        bottom: '0.1dvw',
                        backgroundColor: 'white',
                        transition: '0.4s',
                        borderRadius: '50%',
                        boxShadow: '0 0.1dvw 0.3dvw rgba(0,0,0,0.5)'
                    }} />
                </span>
            </div>
            <div style={{ 
                fontSize: '0.9dvw',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.1dvw',
                outline: 'none',
                WebkitTapHighlightColor: 'transparent'
            }}>
                <span style={{
                    color: !isChecked ? colorOn : colorOff,
                    fontWeight: !isChecked ? '700' : '400',
                    outline: 'none',
                    WebkitTapHighlightColor: 'transparent'
                }}>
                    {labelOff}
                </span>
                <span style={{
                    color: isChecked ? colorOn : colorOff,
                    fontWeight: isChecked ? '700' : '400',
                    outline: 'none',
                    WebkitTapHighlightColor: 'transparent'
                }}>
                    {labelOn}
                </span>
            </div>
        </label>
    );
};

// Add more settings elements here as needed 