import React, { useState, useRef } from 'react';
import { ToggleSwitch } from './SettingsElements';

interface SettingsProps {
    onSettingChange: (setting: string, value: boolean) => void;
    settings: {
        preferNotationLegible: boolean;
        // Add more settings here as needed
    };
}

const Settings: React.FC<SettingsProps> = ({ onSettingChange, settings }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const openPopup = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
    };

    const closePopup = () => {
        setIsOpen(false);
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.height = '100%';
    };

    return (
        <>
            <span onClick={openPopup} title='Settings'
                style={{
                    border: 'solid',
                    verticalAlign: '-0.4dvw',
                    cursor: 'pointer',
                    borderRadius: '0dvw',
                    borderColor: 'black',
                    backgroundColor: 'black',
                    color: 'black',
                    fontSize: '0.2dvw',
                    width: '1dvw',
                    marginLeft: '0.0dvw'
                }}>
                {'\u00a0'}
                {<img src="/images/settingsicon-500.png" alt="" style={{ width: '1.6dvw', verticalAlign: '-0.4dvw' }} />}
                {'\u00a0'}
            </span>

            {isOpen && (
                <div ref={contentRef} style={{
                    position: 'absolute',
                    top: '5%',
                    left: '30%',
                    right: '30%',
                    backgroundColor: '#1a1a1a',
                    padding: '20px',
                    color: '#fff',
                    border: 'groove',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '20px' }}>

                        {'\u00a0'}
                        {<img src="/images/settingsicon-500.png" alt="" style={{ width: '3dvw', verticalAlign: '-0.4dvw' }} />}
                        {'\u00a0'}
                        <span style={{ fontSize: '1.7dvw', fontWeight: '700', color: 'rgb(220, 220, 220)' }}>{'\u00a0'}Settings | </span>
                        <span className='title-text' style={{ fontSize: '1.9dvw', fontWeight: '700' }}>sentisonics</span>
                        <span className='title-text' style={{ fontSize: '0.7dvw' }}> BETA</span>{'\u00a0'}
                    </div>

                    <div style={{ marginBottom: '45px', textAlign: 'left' }}>
                        <ToggleSwitch
                            isChecked={settings.preferNotationLegible}
                            onChange={(checked) => onSettingChange('preferNotationLegible', checked)}
                            labelOff="Sentiment Priority"
                            labelOn="Notation Priority"
                        />
                    </div>

                    {isOpen && (
                        <p style={{ textAlign: 'left' }}>
                            <span onClick={closePopup} title='Close Settings' style={{ textAlign: 'center', border: 'groove', verticalAlign: '-0.0dvw', cursor: 'pointer', borderRadius: '0.4dvw', padding: '0.1dvw', backgroundColor: 'black', color: 'rgb(220,220,220)', fontSize: '1dvw' }}>
                                {'\u00a0Close\u00a0\u2716'}
                            </span>
                        </p>
                    )}

                    {/* Add more settings here */}
                </div>
            )}

            {isOpen && (
                <div onClick={closePopup} style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    zIndex: 999
                }} />
            )}
        </>
    );
};

export default Settings; 